<template>
  <v-container fill-height fluid>
    <v-row justify="center">
      <v-form ref="form" lazy-validation style="width: 400px">
        <div class="mx-3">
          <v-icon color="black" size="30px">mdi-lock</v-icon> new password
          <div class="mx-1">
            <v-text-field placeholder="new" type="password" v-model="editedItem.newPassword" required
              :rules="[rules.password.required]"></v-text-field>
          </div>
        </div>
        <div class="mx-3">
          <v-icon color="black" size="30px">mdi-lock-check</v-icon>
          confirm password
          <div class="mx-1">
            <v-text-field placeholder="confirm" type="password" v-model="editedItem.confirmPassword" required
              :rules="[rules.password.required]"></v-text-field>
          </div>
        </div>
        <v-card-actions>
          <v-btn color="#2c4f91" dark large block @click="changePassword">Change</v-btn>
        </v-card-actions>
      </v-form>
    </v-row>
    <v-row v-if="userLevel == 299" justify="center">
      <v-col cols="2">
        <v-btn color="red" dark large block @click="resetDBConfirm">
          RESET DB
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogResetDB" persistent max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Are you sure you want to reset data base?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeResetDB">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="resetDB">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar color="green" v-model="alert">
      <div class="text-center">PASSWORD CHANGED!</div>
    </v-snackbar>

    <v-snackbar color="green" v-model="alertResetDB">
      <div class="text-center">RESET DB OK!</div>
    </v-snackbar>
  </v-container>
</template>

<script>
import { bus } from '../main';
import C from "../../../common/constants";

export default {
  data: () => ({
    alert: false,
    alertResetDB: false,
    dialog: false,
    dialogResetDB: false,
    items: [],
    editedIndex: -1,
    userLevel: -1,
    editedItem: {
      newPassword: "",
      confirmPassword: "",
    },
    defaultItem: {
      newPassword: "",
      confirmPassword: "",
    },
    rules: {
      email: {
        required: (v) => !!v || "E-Mail is required",
      },
      password: {
        required: (v) => !!v || "Password is required",
      },
    },
  }),

  created() {
    this.initialize();
  },

  watch: {
    alert(new_val) {
      if (new_val) {
        setTimeout(() => {
          this.alert = false;
        }, 1000);
      }
    },
    alertResetDB(new_val) {
      if (new_val) {
        setTimeout(() => {
          this.alertResetDB = false;
        }, 1000);
      }
    },
  },

  methods: {
    initialize() {
      this.isLogin();
    },

    isLogin() {
      try {
        this.$http.get("/api/auth/status").then((res) => {
          if (res.status === 200) {
            // 로그인 성공시 처리해줘야할 부분
            //this.$router.push("/DashboardPage");
          }
          this.userLevel = res.status;
          console.log("userLevel:", this.userLevel);
        });
      } catch (error) {
        console.error(error);
      }
    },

    changePassword() {
      if (!this.$refs.form.validate()) return;
      try {
        this.$http
          .post("/api/auth/password", { params: this.editedItem })
          .then((res) => {
            this.$refs.form.reset();
            if (res.status === 200) {
              // 성공시 처리해줘야할 부분
              this.alert = true;
            } else {
              alert("password missmatch!");
            }
          })
          .catch((exception) => {
            if (exception.response.status == C.ERROR_LOGIN_FAILED) {
              bus.$emit("message", "logout");
              return;
            }
          });
      } catch (error) {
        console.error('error.status', error.status);
      }
    },

    resetDBConfirm() {
      this.dialogResetDB = true;
    },

    closeResetDB() {
      this.dialogResetDB = false;
    },

    resetDB() {
      console.log("resetDataBase!!!");
      try {
        this.$http.post("/api/resetDB").then((res) => {
          if (res.status === 200) {
            // 성공시 처리해줘야할 부분
            this.alertResetDB = true;
          } else {
            alert("resetDB Error!!!");
          }
        });
      } catch (error) {
        console.error(error);
      }
      this.dialogResetDB = false;
    },
  },
};
</script>

<style></style>