import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./routes";
import axios from "axios";
import moment from "moment";
import vueMoment from "vue-moment";

Vue.config.productionTip = false;
Vue.prototype.$http = axios;
Vue.use(vueMoment, moment);
export const bus = new Vue();

console.log(process.env.VUE_APP_TITLE);

new Vue({
  vuetify,
  router,
  render: h => h(App),
}).$mount("#app");
