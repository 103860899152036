<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      sort-by="user_no"
      :sort-desc="true"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>User</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="search"
                class="pa-5"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <v-dialog v-model="dialog" persistent max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="green"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                @click="openNewDialog"
              >
                NEW
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form ref="form" lazy-validation>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.email"
                          label="E-Mail*"
                          :rules="[rules.email.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.password"
                          type="password"
                          label="Password*"
                          :rules="[rules.password.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.name"
                          label="Name*"
                          :rules="[rules.name.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.tel"
                          label="TEL"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-autocomplete
                          v-model="editedItem.group_name"
                          :items="groups"
                          label="Group*"
                          :rules="[rules.group_name.required]"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="logDialog" persistent max-width="800px">
            <v-card>
              <v-card-title>
                <span class="text-h5">Download Log</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-data-table
                    :headers="log_headers"
                    :items="logs"
                    sort-by="user_no"
                    class="elevation-1"
                  ></v-data-table>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeLogDialog">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" persistent max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon color="primary" small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon color="primary" small class="mr-2" @click="showLog(item)">
          mdi-view-list
        </v-icon>
        <v-icon color="error" small class="mr-2" @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:[`item.last_login_time`]="{ item }">
        <span>{{ new Date(item.last_login_time).toLocaleString() }}</span>
      </template>
      <template v-slot:[`item.last_access_time`]="{ item }">
        <span>{{ new Date(item.last_access_time).toLocaleString() }}</span>
      </template>
      <!-- <template v-slot:no-data>
      <v-btn color="primary" @click="initialize"> Reset </v-btn>
    </template> -->
    </v-data-table>
  </v-container>
</template>

<script>
import C from "../../../common/constants";

export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    logDialog: false,
    user_level: -1,
    search: "",
    log_headers: [
      { text: "NO", align: "start", sortable: false, value: "dlog_no" },
      { text: "Filename", value: "filename" },
      { text: "Date", value: "dlog_date" },
    ],
    items: [],
    groups: [],
    logs: [],
    editedIndex: -1,
    editedItem: {
      user_no: 0,
      email: "",
      password: "",
      name: "",
      tel: "",
      mobile: "",
      group_name: "",
    },
    defaultItem: {
      user_no: 0,
      email: "",
      name: "",
      tel: "",
      mobile: "",
      group_name: "",
    },
    rules: {
      email: {
        required: (v) =>
          !!v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      },
      password: {
        required: (v) => !!v || "Password is required",
      },
      name: {
        required: (v) => !!v || "Name is required",
      },
      group_name: {
        required: (v) => (v && v.length > 0) || "Group is required",
      },
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New User" : "Edit User";
    },
    headers() {
      let headers = [
      { text: "No.", value: "user_no", align: "center", sortable: false },
      { text: "Group", value: "group_name", align: "center", sortable: false },
      { text: "Name", value: "name", align: "center", sortable: false },
      { text: "E-Mail", value: "email", align: "center", sortable: false },
      { text: "TEL", value: "tel", align: "center", sortable: false },
      { text: "Actions", value: "actions", align: "center", sortable: false },
      ];
      if (this.user_level == C.LOGIN_ST_ROOT) {
        headers.push({
          text: "Level",
          value: "level",
          align: "center",
          sortable: false,
        });
        headers.push({
          text: "TempPassword",
          value: "temp_password",
          align: "center",
          sortable: false,
        });
        headers.push({
          text: "LastLoginTime",
          value: "last_login_time",
          align: "center",
          sortable: false,
        });
        headers.push({
          text: "LastAccessTime",
          value: "last_access_time",
          align: "center",
          sortable: false,
        });
      }
      return headers;
    },
  },

  watch: {
    dialog(val) {
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
        val || this.close();
      }
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.loading = true;
      this.$http.get("/api/user/select").then((response) => {
        this.items = response.data;
        this.user_level = response.status;
        this.loading = false;
      });
      this.loadGroup();
    },

    openNewDialog() {
      // if (this.$refs.form) {
      //   this.$refs.form.reset();
      // }
      // this.loadGroup();
    },

    loadGroup() {
      this.$http.get("/api/group/select").then((response) => {
        for (var key in response.data) {
          //console.log(key, response.data[key].group_name);
          this.groups[key] = response.data[key].group_name;
        }
        this.loading = false;
      });
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.password = "";
      this.dialog = true;
      console.log("this.items[key].group_no:" + this.groups[this.editedIndex]);
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$http
        .get("/api/user/delete", { params: this.editedItem })
        .then(() => {
          this.loading = false;
        });
      this.items.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    showLog(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.logDialog = true;
      this.$http
        .get("/api/dlog/select", { params: this.editedItem })
        .then((response) => {
          this.logs = response.data;
          for (var key in response.data) {
            this.logs[key].dlog_date = this.$moment(
              this.logs[key].dlog_date
            ).format("YYYY-MM-DD HH:mm:ss");
          }
          this.loading = false;
        });
    },

    closeLogDialog() {
      this.logDialog = false;
    },

    save() {
      const validate = this.$refs.form.validate();
      if (!validate) {
        return;
      }
      if (this.editedIndex > -1) {
        Object.assign(this.items[this.editedIndex], this.editedItem);
        this.$http
          .post("/api/user/update", { params: this.editedItem })
          .then(() => {
            this.loading = false;
          });
      } else {
        //this.items.push(this.editedItem);
        //console.log("items:" + this.editedItem);
        this.$http
          .post("/api/user/insert", { params: this.editedItem })
          .then((response) => {
            this.loading = false;
            this.items = response.data;
          })
          .catch((error) => {
            console.log(error);
            alert("duplicated e-mail!");
          });
      }
      this.close();
    },
  },
};
</script>

<style>
</style>