module.exports = Object.freeze({
  // root
  menu_level_0: [
    {
      name: "DashBoard",
      title: "Dashboard",
      icon: "mdi-view-dashboard",
      to: "/DashboardPage"
    }, {
      name: "Admin",
      title: "Admin",
      icon: "mdi-account-supervisor",
      to: "/AdminPage"
    }, {
      name: "Group",
      title: "Group",
      icon: "mdi-group",
      to: "/GroupPage"
    }, {
      name: "User",
      title: "User",
      icon: "mdi-account",
      to: "/UserPage"
    }, {
      name: "Product1",
      title: "BMS",
      icon: "mdi-upload",
      children: [
        {
          title: "ATS7001",
          icon: "mdi-memory",
          to: "/BMP1Page"
        }, {
          title: "ATS7002",
          icon: "mdi-memory",
          to: "/BMP2Page"
        }
      ]
    }, {
      name: "Product2",
      title: "BDS",
      icon: "mdi-upload",
      children: [
        {
          title: "BDIC",
          icon: "mdi-memory",
          to: "/BDP1Page"
        }
      ]
    }, {
      name: "CustomerSupport",
      title: "Customer Support",
      icon: "mdi-face-agent",
      to: "/CustomerSupportPage"
    }, {
      name: "Password",
      title: "Password",
      icon: "mdi-lock",
      to: "/PasswordPage"
    }
  ],
  // admin 1tier
  menu_level_1: [
    {
      name: "DashBoard",
      title: "Dashboard",
      icon: "mdi-view-dashboard",
      to: "/DashboardPage"
    }, {
      name: "Admin",
      title: "Admin",
      icon: "mdi-account-supervisor",
      to: "/AdminPage"
    }, {
      name: "Group",
      title: "Group",
      icon: "mdi-group",
      to: "/GroupPage"
    }, {
      name: "User",
      title: "User",
      icon: "mdi-account",
      to: "/UserPage"
    }, {
      name: "Product1",
      title: "BMS",
      icon: "mdi-upload",
      children: [
        {
          title: "ATS7001",
          icon: "mdi-memory",
          to: "/BMP1Page"
        }, {
          title: "ATS7002",
          icon: "mdi-memory",
          to: "/BMP2Page"
        }
      ]
    }, {
      name: "Product2",
      title: "BDS",
      icon: "mdi-upload",
      children: [
        {
          title: "BDIC",
          icon: "mdi-memory",
          to: "/BDP1Page"
        }
      ]
    }, {
      name: "CustomerSupport",
      title: "Customer Support",
      icon: "mdi-face-agent",
      to: "/CustomerSupportPage"
    }, {
      name: "Password",
      title: "Password",
      icon: "mdi-lock",
      to: "/PasswordPage"
    }
  ],
  // admin 2tier
  menu_level_2: [
    {
      name: "DashBoard",
      title: "Dashboard",
      icon: "mdi-view-dashboard",
      to: "/DashboardPage"
    }, {
      name: "Group",
      title: "Group",
      icon: "mdi-group",
      to: "/GroupPage"
    }, {
      name: "User",
      title: "User",
      icon: "mdi-account",
      to: "/UserPage"
    }, {
      name: "Product1",
      title: "BMS",
      icon: "mdi-upload",
      children: [
        {
          title: "ATS7001",
          icon: "mdi-memory",
          to: "/BMP1Page"
        }, {
          title: "ATS7002",
          icon: "mdi-memory",
          to: "/BMP2Page"
        }
      ]
    }, {
      name: "Product2",
      title: "BDS",
      icon: "mdi-upload",
      children: [
        {
          title: "BDIC",
          icon: "mdi-memory",
          to: "/BDP1Page"
        }
      ]
    }, {
      name: "CustomerSupport",
      title: "Customer Support",
      icon: "mdi-face-agent",
      to: "/CustomerSupportPage"
    }, {
      name: "Approver",
      title: "Approver",
      icon: "mdi-account-check",
      to: "/ApproverPage"
    }, {
      name: "Password",
      title: "Password",
      icon: "mdi-lock",
      to: "/PasswordPage"
    }
  ],
  // ATS User
  menu_level_3: [
    {
      name: "Product1",
      title: "BMS",
      icon: "mdi-upload",
      children: [
        {
          title: "ATS7001",
          icon: "mdi-memory",
          to: "/BMP1Page"
        }, {
          title: "ATS7002",
          icon: "mdi-memory",
          to: "/BMP2Page"
        }
      ]
    }, {
      name: "Product2",
      title: "BDS",
      icon: "mdi-upload",
      children: [
        {
          title: "BDIC",
          icon: "mdi-memory",
          to: "/BDP1Page"
        }
      ]
    }, {
      name: "Password",
      title: "Password",
      icon: "mdi-lock",
      to: "/PasswordPage"
    }
  ],
  // External User
  menu_level_4: [
    {
      name: "Product1",
      title: "BMS",
      icon: "mdi-upload",
      children: [
        {
          title: "ATS7001",
          icon: "mdi-memory",
          to: "/BMP1Page"
        }, {
          title: "ATS7002",
          icon: "mdi-memory",
          to: "/BMP2Page"
        }
      ]
    }, {
      name: "Product2",
      title: "BDS",
      icon: "mdi-upload",
      children: [
        {
          title: "BDIC",
          icon: "mdi-memory",
          to: "/BDP1Page"
        }
      ]
    }, {
      name: "QnA",
      title: "QnA",
      icon: "mdi-account-question",
      to: "/QnAPage"
    }, {
      name: "ContactUs",
      title: "Contact Us",
      icon: "mdi-phone-in-talk",
      to: "/ContactUsPage"
    }, {
      name: "Password",
      title: "Password",
      icon: "mdi-lock",
      to: "/PasswordPage"
    }
  ]
});