<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items"
      sort-by="voc_no"
      :sort-desc="true"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Customer Support</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" persistent max-width="800px">
            <!-- <template v-slot:activator="{ on, attrs }">
              <v-btn color="green" dark class="mb-2" v-bind="attrs" v-on="on">
                NEW
              </v-btn>
            </template> -->
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form ref="form" lazy-validation>
                    <v-row>
                      <v-col cols="4">
                        <v-autocomplete
                          v-model="editedItem.application"
                          :items="applications"
                          label="Application"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="4">
                        <v-autocomplete
                          v-model="editedItem.product_name"
                          :items="products"
                          label="Product type"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="4">
                        <v-autocomplete
                          v-model="editedItem.category_name"
                          :items="categories"
                          label="Category"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-spacer></v-spacer>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-textarea
                          v-model="editedItem.comment"
                          color="cyan"
                          label="Comment"
                          readonly
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn
                  v-if="editedItem.status == 'open'"
                  color="blue darken-1"
                  text
                  @click="save"
                >
                  Proceed
                </v-btn>
                <v-btn
                  v-if="editedItem.status == 'proceeding'"
                  color="blue darken-1"
                  text
                  @click="closeVoc"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogContent" persistent max-width="800px">
            <v-card>
              <v-card-text>
                <v-container>
                  <v-form ref="form" lazy-validation>
                    <v-banner>
                      <v-row>
                        <v-col cols="8">
                          {{ editedItem.title }}
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col>
                          <h5>
                            {{ editedItem.date }}
                          </h5>
                        </v-col>
                      </v-row>
                    </v-banner>
                    <v-row>
                      <v-col>
                        <v-spacer></v-spacer>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        {{ editedItem.application }} ▶
                        {{ editedItem.product_name }} ▶
                        {{ editedItem.category_name }}
                      </v-col>
                      <v-col>
                        <v-spacer></v-spacer>
                      </v-col>
                      <v-col cols="3">
                        Status
                        <v-btn
                          v-if="editedItem.status == 'open'"
                          color="blue darken-1"
                          text
                        >
                          NEW
                        </v-btn>
                        <v-btn
                          v-if="editedItem.status == 'proceeding'"
                          color="blue darken-1"
                          text
                          @click="closeVoc"
                        >
                          Close
                        </v-btn>
                        <v-btn
                          v-if="editedItem.status == 'closing'"
                          color="pink darken-1"
                          text
                          @click="closeVoc"
                        >
                          Closing
                        </v-btn>
                        <v-btn
                          v-if="editedItem.status == 'closed'"
                          color="gray darken-1"
                          text
                        >
                          Closed
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-textarea
                          v-if="editedItem.depth == 0"
                          v-model="editedItem.comment"
                          readonly
                        >
                        </v-textarea>
                        <v-textarea
                          v-if="editedItem.depth > 0"
                          v-model="editedItem.sub_comment"
                          readonly
                        >
                        </v-textarea>
                      </v-col>
                    </v-row>
                    <v-row align="center">
                      <v-col cols="9">
                        <v-textarea
                          v-model="editedItem.new_comment"
                          label="Comment"
                        >
                        </v-textarea>
                      </v-col>
                      <v-col>
                        <v-btn color="yellow darken-1" @click="saveSubComment">
                          Add Comment
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeSubComment">
                  CANCEL
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" persistent max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:[`item.voc_no`]="{ item }">
        <template v-if="item.depth == 0"> {{ item.voc_no }} </template>
      </template>
      <template v-slot:[`item.title`]="{ item }">
        <v-container>
          <v-row>
            <v-col>
              <span
                v-for="k in item.depth"
                :key="k"
                style="padding-left: 12px"
              ></span>
              <v-icon v-if="item.depth"> mdi-subdirectory-arrow-right </v-icon>
              <a @click="clickItem(item)">
                {{ item.title }}
              </a>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:[`item.user_name`]="{ item }">
        <template v-if="item.depth == 0"> {{ item.user_name }} </template>
        <template v-elif> {{ item.name }} </template>
      </template>
      <template v-slot:[`item.user_email`]="{ item }">
        <template v-if="item.depth == 0"> {{ item.user_email }} </template>
        <template v-elif> {{ item.email }} </template>
      </template>
      <!-- <template v-slot:[`item.category_name`]="{ item }">
        <template v-if="item.depth == 0">
          <a @click="editItem(item)">
            {{ item.category_name }}
          </a>
        </template>
        <template v-else>
          {{ item.category_name }}
        </template>
      </template> -->
      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          v-if="item.depth == 0"
          color="blue lighten-3"
          v-model="item.status"
          >{{ item.status }}</v-chip
        >
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { HTTP_ST_209 } from "../../../common/constants";
export default {
  data: () => ({
    dialog: false,
    dialogContent: false,
    dialogDelete: false,
    applications: ["BMS", "BDS"],
    products: ["ATS7001", "ATS7002"],
    categories: [],
    nda: "All attachments are confidential and can configure Autosilicon's legal privilege information. You will be notified that any disclosure, copying, distribution or action relating to this information is strictly prohibited unless you are the intended recipient. You are not authorized to preserve or resend this attachment in any form and must be destroyed immediately.",
    status: ["processing", "closed"],
    headers: [
      { text: "No.", value: "voc_no", align: "center", sortable: false },
      {
        text: "Application",
        value: "application",
        align: "center",
        sortable: false,
      },
      {
        text: "Product",
        value: "product_name",
        align: "center",
        sortable: false,
      },
      {
        text: "Category",
        value: "category_name",
        align: "center",
        sortable: false,
      },
      { text: "Title", value: "title", align: "center", sortable: false },
      { text: "Writer", value: "user_name", align: "center", sortable: false },
      { text: "Date", value: "date", align: "center", sortable: false },
      { text: "Status", value: "status", align: "center", sortable: false },
    ],
    items: [],
    editedIndex: -1,
    editedItem: {
      voc_no: 0,
      application_name: "BMS",
      product_name: "ATS7001",
      category_name: "Datasheet",
      comment: "",
      status: "",
      sub_comment: "",
      new_comment: "",
    },
    defaultItem: {
      voc_no: 0,
      application_name: "BMS",
      product_name: "ATS7001",
      category_name: "Datasheet",
      comment: "",
      status: "",
      sub_comment: "",
      new_comment: "",
    },
    rules: {
      name: {
        required: (v) => !!v || "Category Name is required",
      },
    },
  }),

  computed: {
    formTitle() {
      return "Customer Support";
    },
  },

  watch: {
    dialog(val) {
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
      val || this.close();
    },
    // dialogContent(val) {
    //   if (this.$refs.form) {
    //     this.$refs.form.reset();
    //   }
    //   val || this.close();
    // },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.loadCategory();
      this.$http.get("/api/voc/select").then((response) => {
        this.items = response.data;
        this.updateDateFormat();
        this.loading = false;
      });
    },

    updateDateFormat() {
      for (var key in this.items) {
        this.items[key].date = this.$moment(this.items[key].date).format(
          "YYYY-MM-DD HH:mm"
        );
      }
    },

    loadCategory() {
      this.$http.get("/api/category/select").then((response) => {
        let items = response.data;
        for (var key in items) {
          // console.log(key, response.data[key].cat_name);
          this.categories.push(items[key].cat_name);
        }
      });
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$http
        .post("/api/voc/delete", { params: this.editedItem })
        .then((response) => {
          if (response.status === HTTP_ST_209) {
            this.items = response.data;
            this.updateDateFormat();
            alert("delete fail!");
          }
          this.loading = false;
        });
      this.items.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (!this.$refs.form.validate()) return;
      if (this.editedIndex > -1) {
        Object.assign(this.items[this.editedIndex], this.editedItem);
        this.$http
          .post("/api/voc/proceed", { params: this.editedItem })
          .then((response) => {
            this.items = response.data;
            this.updateDateFormat();
            console.log(this.editedIndex);
            this.loading = false;
          });
      } else {
        this.$http
          .post("/api/voc/insert", { params: this.editedItem })
          .then((response) => {
            this.items = response.data;
            this.updateDateFormat();
            this.loading = false;
          });
      }
      this.close();
    },

    clickItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogContent = true;
    },

    saveSubComment() {
      if (!this.$refs.form.validate()) return;
      if (this.editedIndex > -1) {
        Object.assign(this.items[this.editedIndex], this.editedItem);
        this.$http
          .post("/api/voc_sub/insert", { params: this.editedItem })
          .then((response) => {
            this.items = response.data;
            this.updateDateFormat();
            this.loading = false;
          });
        if (this.editedItem.status === "open") {
          this.$http
            .post("/api/voc/proceed", { params: this.editedItem })
            .then((response) => {
              this.items = response.data;
              this.updateDateFormat();
              console.log(this.editedIndex);
              this.loading = false;
            });
        }
      }
      this.dialogContent = false;
      this.close();
    },

    closeSubComment() {
      this.dialogContent = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeVoc() {
      this.dialogContent = false;
      this.$http
        .post("/api/voc/close", { params: this.editedItem })
        .then((response) => {
          this.items = response.data;
          this.updateDateFormat();
          this.loading = false;
        });
    },
  },
};
</script>

<style>
</style>