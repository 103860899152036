<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card class="elevation-3">
          <v-toolbar flat>
            <v-toolbar-title>Contact Us</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="elevation-3">
          <v-card-title
            >• Headquarters and Daejeon Research Center</v-card-title
          >
          <v-img
            max-height="400"
            contain
            class="align-self-stretch"
            src="@/assets/ats_map.png"
          ></v-img>
          <v-row align="center" class="mx-4 mt-2">
            <v-img max-width="20" src="@/assets/icon_map.svg"></v-img>
            <v-card-subtitle>
              Headquarters: C-809, 128, Beobwon-ro, Songpa-gu, Seoul, 05854,
              Korea
            </v-card-subtitle>
          </v-row>
          <v-row align="center" class="mx-4 mt-0">
            <v-img max-width="20" src="@/assets/icon_map.svg"></v-img>
            <v-card-subtitle>
              Research Center: 2F, 272-7, Munji-ro, Yuseong-gu, Daejeon,
                  34050, Korea
            </v-card-subtitle>
          </v-row>
          <v-row align="center" class="mx-4 mt-0">
            <v-img max-width="20" src="@/assets/icon_tel.svg"></v-img>
            <v-card-subtitle>
              Tel: 042-488-8039
            </v-card-subtitle>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="elevation-3">
          <v-card-title
            >• Contact by person in charge</v-card-title
          >
          <v-row align="center" class="mx-4">
            <v-img max-width="20" src="@/assets/icon_mail.svg"></v-img>
            <v-card-subtitle>
              E-mail: tech-support@autosilicon.com
            </v-card-subtitle>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    dialogContent: false,
    dialogDelete: false,
    products: ["ATS7001", "ATS7002"],
    categories: [],
    nda: "비밀유지 및 보안서약",
    rules: {
      name: {
        required: (v) => !!v || "Category Name is required",
      },
    },
  }),

  computed: {},

  watch: {},

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      // this.loadCategory();
      // this.$http.get("/api/voc/select").then((response) => {
      //   this.items = response.data;
      //   this.loading = false;
      // });
    },
  },
};
</script>

<style>
</style>