<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items"
      sort-by="user_no"
      :sort-desc="true"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Admin</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" persistent max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="green"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                v-show="true"
                @click="openNewDialog"
              >
                NEW
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form ref="form" lazy-validation>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.email"
                          label="E-Mail*"
                          :rules="[rules.email.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.password"
                          type="password"
                          label="Password*"
                          :rules="[rules.password.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.name"
                          label="Name*"
                          :rules="[rules.name.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.tel"
                          label="TEL"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-autocomplete
                          v-model="editedItem.group_name"
                          :items="groups"
                          label="Team*"
                          :rules="[rules.group_name.required]"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" persistent max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon color="primary" small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon color="error" small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:[`item.last_login_time`]="{ item }">
        <span>{{ new Date(item.last_login_time).toLocaleString() }}</span>
      </template>
      <template v-slot:[`item.last_access_time`]="{ item }">
        <span>{{ new Date(item.last_access_time).toLocaleString() }}</span>
      </template>
      <!-- <template v-slot:no-data>
      <v-btn color="primary" @click="initialize"> Reset </v-btn>
    </template> -->
    </v-data-table>
  </v-container>
</template>

<script>
import C from "../../../common/constants";

export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    user_level: -1,
    items: [],
    groups: [],
    editedIndex: -1,
    editedItem: {
      user_no: 0,
      email: "",
      password: "",
      name: "",
      tel: "",
      group_name: "",
    },
    defaultItem: {
      user_no: 0,
      email: "",
      password: "",
      name: "",
      tel: "",
      group_name: "",
    },
    rules: {
      email: {
        required: (v) =>
          !!v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      },
      password: {
        required: (v) => !!v || "Password is required",
      },
      name: {
        required: (v) => !!v || "Name is required",
      },
      group_name: {
        required: (v) => (v && v.length > 0) || "Team is required",
      },
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Admin" : "Edit Admin";
    },
    headers() {
      let headers = [
        { text: "No.", value: "user_no", align: "center", sortable: false },
        { text: "Team", value: "group_name", align: "center", sortable: false },
        { text: "Name", value: "name", align: "center", sortable: false },
        { text: "E-Mail(ID)", value: "email", align: "center", sortable: false },
        { text: "TEL", value: "tel", align: "center", sortable: false },
        { text: "Actions", value: "actions", align: "center", sortable: false },
      ];
      if (this.user_level == C.LOGIN_ST_ROOT) {
        headers.push({
          text: "Level",
          value: "level",
          align: "center",
          sortable: false,
        });
        headers.push({
          text: "TempPassword",
          value: "temp_password",
          align: "center",
          sortable: false,
        });
        headers.push({
          text: "LastLoginTime",
          value: "last_login_time",
          align: "center",
          sortable: false,
        });
        headers.push({
          text: "LastAccessTime",
          value: "last_access_time",
          align: "center",
          sortable: false,
        });
      }
      return headers;
    },
  },

  watch: {
    dialog(val) {
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
        val || this.close();
      }
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.$http.get("/api/admin/select").then((response) => {
        this.items = response.data;
        this.user_level = response.status;
        this.loading = false;
      });
      this.loadTeam();
    },

    openNewDialog() {},

    loadTeam() {
      this.$http.get("/api/team/select").then((response) => {
        for (var key in response.data) {
          this.groups[key] = response.data[key].group_name;
        }
        this.loading = false;
      });
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.password = "";
      this.dialog = true;
      // this.$http.get("/api/group/select").then((response) => {
      //   for (var key in response.data) {
      //     //console.log(key, response.data[key].group_name);
      //     this.items[key].team_name = response.data[key].group_name;
      //   }
      //   this.editedItem.password = "";
      //   this.editedItem.group_name = item.group_name;
      //   this.loading = false;
      // });
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$http
        .get("/api/admin/delete", { params: this.editedItem })
        .then(() => {
          this.loading = false;
          this.items.splice(this.editedIndex, 1);
        })
        .catch((error) => {
          console.log(error);
          alert("연관된 파일이 존재하여 삭제가 불가능합니다!");
        });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      const validate = this.$refs.form.validate();
      if (!validate) {
        return;
      }
      if (this.editedIndex > -1) {
        Object.assign(this.items[this.editedIndex], this.editedItem);
        this.$http
          .post("/api/admin/update", { params: this.editedItem })
          .then(() => {
            //this.items = response.data;
            this.loading = false;
          });
      } else {
        this.$http
          .post("/api/admin/insert", { params: this.editedItem })
          .then((response) => {
            this.items = response.data;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            alert("duplicated e-mail!");
          });
      }
      this.close();
    },
  },
};
</script>

<style>
</style>