<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      loading-text="Loading... Please wait"
      sort-by="group_no"
      :sort-desc="true"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Group</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" persistent max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="green" dark class="mb-2" v-bind="attrs" v-on="on">
                NEW
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form ref="form" lazy-validation>
                    <v-row>
                      <v-col>
                        <v-checkbox
                          v-if="editedIndex === -1"
                          v-model="editedItem.ats_user"
                          :label="`ATS`"
                        >
                        </v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.group_name"
                          label="NAME*"
                          :rules="[rules.name.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.comment"
                          label="COMMENT"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" persistent max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.ats_user`]="{ item }">
        <v-checkbox
          class="d-inline-flex ml-2"
          disabled
          v-model="item.ats_user"
        ></v-checkbox>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon color="primary" small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon color="error" small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
      <!-- <template v-slot:no-data>
      <v-btn color="primary" @click="initialize"> Reset </v-btn>
    </template> -->
    </v-data-table>
  </v-container>
</template>

<script>
import C from "../../../common/constants";

export default {
  created() {
    this.initialize();
  },

  data: () => ({
    dialog: false,
    dialogDelete: false,
    loading: false,
    headers: [
      { text: "No.", value: "group_no", align: "center", sortable: false },
      { text: "Name", value: "group_name", align: "center" },
      { text: "ATS", value: "ats_user", align: "center", sortable: false },
      { text: "Description", value: "comment", align: "center" },
      { text: "Actions", value: "actions", align: "center", sortable: false },
    ],
    items: [],
    editedIndex: -1,
    editedItem: {
      group_no: 0,
      ats_user: false,
      group_name: "",
      comment: "",
    },
    defaultItem: {
      group_no: 0,
      ats_user: false,
      group_name: "",
      comment: "",
    },
    rules: {
      name: {
        required: (v) => !!v || "Group Name is required",
      },
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Group" : "Edit Group";
    },
  },

  watch: {
    dialog(val) {
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
        val || this.close();
      }
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    initialize() {
      this.$http.get("/api/group/select").then((response) => {
        this.items = response.data;
        for (var key in response.data) {
          if (response.data[key].group_level == C.GROUP_LEVEL_ATS) {
            this.items[key].ats_user = true;
          } else {
            this.items[key].ats_user = false;
          }
        }
        this.loading = false;
      });
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$http
        .get("/api/group/delete", { params: this.editedItem })
        .then((response) => {
          if (response.status === C.ERROR_DELETE_FAILED) {
            this.items = response.data;
            alert("delete fail!");
          }
          this.items.splice(this.editedIndex, 1);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          alert("그룹 소속 사용자가 존재하여 그룹을 삭제할 수 없습니다!");
        });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (!this.$refs.form.validate()) return;
      if (this.editedIndex > -1) {
        Object.assign(this.items[this.editedIndex], this.editedItem);
        this.$http
          .get("/api/group/update", { params: this.editedItem })
          .then((response) => {
            this.items = response.data;
            for (var key in response.data) {
              if (response.data[key].group_level == C.GROUP_LEVEL_ATS) {
                this.items[key].ats_user = true;
              } else {
                this.items[key].ats_user = false;
              }
            }
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$http
          .get("/api/group/insert", { params: this.editedItem })
          .then((response) => {
            this.items = response.data;
            for (var key in response.data) {
              if (response.data[key].group_level == C.GROUP_LEVEL_ATS) {
                this.items[key].ats_user = true;
              } else {
                this.items[key].ats_user = false;
              }
            }
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
          });
      }
      this.close();
    },
  },
};
</script>

<style>
</style>