module.exports = Object.freeze({
    // root
    ROOT_ID: 'root',
    ROOT_PW: 'Ats#8056',
    // product type
    BMS: 'BMS',
    BDS: 'BDS',
    // product name
    ATS7001: 'ATS7001',
    ATS7002: 'ATS7002',
    // Level
    ADMIN_LEVEL_ROOT: 99,
    USER_LEVEL_0: 0,
    USER_LEVEL_1: 1,
    USER_LEVEL_2: 2,
    USER_LEVEL_3: 3,
    USER_LEVEL_4: 4,
    // Type
    USER_TYPE_1: 1,
    USER_TYPE_2: 2,
    USER_TYPE_3: 3,
    USER_TYPE_101: 101,
    USER_TYPE_102: 102,
    USER_TYPE_103: 103,
    USER_TYPE_1001: 1001,
    USER_TYPE_1002: 1002,
    USER_TYPE_1003: 1003,
    USER_TYPE_10000: 10000,
    // Status
    HTTP_ST_200: 200,
    HTTP_ST_201: 201,
    HTTP_ST_202: 202,
    HTTP_ST_203: 203,
    HTTP_ST_204: 204,
    HTTP_ST_207: 207,
    HTTP_ST_208: 208,
    HTTP_ST_209: 209,
    // Login Status
    LOGIN_ST_USER_L1: 201,
    LOGIN_ST_USER_L2: 202,
    LOGIN_ST_ADMIN_L1: 210,
    LOGIN_ST_ADMIN_L2: 220,
    LOGIN_ST_ROOT: 299,
    // Group Level
    GROUP_LEVEL_ADMIN_L1: 1,
    GROUP_LEVEL_ADMIN_L2: 2,
    GROUP_LEVEL_ATS: 3,
    GROUP_LEVEL_CUSTOMER: 4,
    // Error
    ERROR_LOGIN_FAILED: 401,
    ERROR_LOGIN_MAX_FAILED: 402,
    ERROR_NOT_FOUND: 404,
    ERROR_DELETE_FAILED: 408,
    ERROR_DUP_ENTRY: 409,
});