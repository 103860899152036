<template>
  <v-container class="grey lighten-5" fluid fill-height pa-10>
    <v-row>
      <!-- BMS Updated Files -->
      <v-col cols="3">
        <v-card elevation="5" height="350px">
          <v-toolbar dense color="green lighten-5">
            <v-icon class="mr-2"> mdi-file </v-icon>
            <v-toolbar-title>Updated Files(BMS)</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn :to="`/BMP1Page`" icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-toolbar>

          <v-container class="text-center">
            <v-row>
              <v-col>
                <v-icon color="green lighten-1" class="mt-15" x-large>
                  mdi-file
                </v-icon>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-chip color="primary lighten-1" x-large>
                  {{
                    info.updated_files.bms_ats7001 +
                    info.updated_files.bms_ats7002
                  }}
                </v-chip>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <!-- BDS Updated Files -->
      <v-col cols="3">
        <v-card elevation="5" height="350px">
          <v-toolbar dense color="green lighten-5">
            <v-icon class="mr-2"> mdi-file </v-icon>
            <v-toolbar-title>Updated Files(BDS)</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn :to="`/BDP1Page`" icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-toolbar>

          <v-container class="text-center">
            <v-row>
              <v-col>
                <v-icon color="green lighten-1" class="mt-15" x-large>
                  mdi-file
                </v-icon>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-chip color="primary lighten-1" x-large>
                  {{ info.updated_files.bds_product }}
                </v-chip>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <!-- Contact Us -->
      <v-col cols="6">
        <v-card elevation="5" height="350px">
          <v-toolbar dense color="yellow lighten-5">
            <v-icon class="mr-2"> mdi-face-agent </v-icon>
            <v-toolbar-title>Contact Us</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn :to="`/CustomerSupportPage`" icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-toolbar>
          <v-container class="text-center pa-5">
            <v-row>
              <v-col cols="4">
                <v-toolbar dense color="grey lighten-4" class="mt-15">
                  <v-spacer />
                  <v-toolbar-title>Open</v-toolbar-title>
                  <v-spacer />
                </v-toolbar>
              </v-col>
              <v-col cols="4">
                <v-toolbar dense color="grey lighten-4" class="mt-15">
                  <v-spacer />
                  <v-toolbar-title>Progressing</v-toolbar-title>
                  <v-spacer />
                </v-toolbar>
              </v-col>
              <v-col cols="4">
                <v-toolbar dense color="grey lighten-4" class="mt-15">
                  <v-spacer />
                  <v-toolbar-title>Closed</v-toolbar-title>
                  <v-spacer />
                </v-toolbar>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-chip color="primary lighten-1" x-large>
                  {{ info.contact_us.open }}
                </v-chip>
              </v-col>
              <v-col cols="4">
                <v-chip color="primary lighten-1" x-large>
                  {{ info.contact_us.progressing }}
                </v-chip>
              </v-col>
              <v-col cols="4">
                <v-chip color="primary lighten-1" x-large>
                  {{ info.contact_us.closed }}
                </v-chip>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <!-- Group -->
      <v-col cols="2">
        <v-card elevation="5" height="350px">
          <v-toolbar dense color="red lighten-5">
            <v-icon class="mr-2"> mdi-account-group </v-icon>
            <v-toolbar-title>Group</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn :to="`/GroupPage`" icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-toolbar>
          <v-container class="text-center">
            <v-row>
              <v-col>
                <v-icon color="green lighten-1" class="mt-15" x-large>
                  mdi-account-group
                </v-icon>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-chip color="primary lighten-1" x-large>
                  {{ info.group }}
                </v-chip>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <!-- Users -->
      <v-col cols="2">
        <v-card elevation="5" height="350px">
          <v-toolbar dense color="red lighten-5">
            <v-icon class="mr-2"> mdi-account </v-icon>
            <v-toolbar-title>Users</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn :to="`/UserPage`" icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-toolbar>
          <v-container class="text-center">
            <v-row>
              <v-col>
                <v-icon color="green lighten-1" class="mt-15" x-large>
                  mdi-account
                </v-icon>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-chip color="primary lighten-1" x-large>
                  {{ info.ats_users + info.users }}
                </v-chip>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <!-- Download History -->
      <v-col>
        <v-card elevation="5" height="350px">
          <v-toolbar dense color="blue lighten-5">
            <v-icon class="mr-2"> mdi-view-list </v-icon>
            <v-toolbar-title>Download History</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>

          <v-container>
            <v-data-table
              :headers="headers"
              :items="items"
              sort-by="dlog_no"
              class="elevation-1"
              height="220px"
            >
            </v-data-table>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<template_temp>
  <v-container fluid class="text-center fill-height">
    <v-row>
      <v-col> TBD </v-col>
    </v-row>
  </v-container>
</template_temp>

<script>
export default {
  created() {
    this.initialize();
  },

  data: () => ({
    tab: null,
    user_level: -1,
    headers: [
      { text: "NO", value: "dlog_no", align: "center", sortable: false },
      { text: "Group", value: "group_name", align: "center", sortable: false },
      { text: "Name", value: "name", align: "center", sortable: false },
      { text: "Filename", value: "filename", align: "center", sortable: false },
      {
        text: "Download Date",
        value: "dlog_date",
        align: "center",
        sortable: false,
      },
    ],
    items: [],

    products: [
      { tab: "BMS", product: "ATS7001", num: 1 },
      { tab: "BDS", product: "BDIC", num: 0 },
    ],
    info: {
      updated_files: {
        bms_ats7001: 0,
        bms_ats7002: 0,
        bds_test: 0,
      },
      contact_us: {
        open: 0,
        progressing: 0,
        closed: 0,
      },
      group: 0,
      users: 0,
    },
  }),

  methods: {
    initialize() {
      console.log("initialize..");
      this.$http.get("/api/auth/status").then((response) => {
        this.user_level = response.status;
        this.$http.get("/api/dashboard").then((response) => {
          this.info = response.data[0];
          this.items = response.data[1];
          // let rows = response.data[2];
          // console.log(JSON.stringify(this.info));
          this.loading = false;
        });
      });
    },
  },
};
</script>

<style>
</style>