<template>
  <v-app id="ams">
    <v-navigation-drawer
      v-model="drawer"
      disable-resize-watcher
      app
      color="blue lighten-5"
    >
      <v-list-item>
        <v-img src="@/assets/ats_logo.png" contain class="mt-5 mb-5"></v-img>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <template v-for="item in menu_items">
          <v-list-item
            v-if="!item.children"
            :key="item.title"
            link
            :to="item.to"
            sub-group
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group v-else :key="item.to" :group="item.to">
            <template #activator>
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-for="subItem in item.children">
              <v-list-item
                v-if="!subItem.children"
                class="ml-5"
                :key="subItem.children"
                link
                :to="subItem.to"
              >
                <v-list-item-icon>
                  <v-icon>{{ subItem.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-group>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar color="primary" v-if="isLogin" absolute dark app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>Autosilicon Customer Service Center</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon class="mr-2" large>mdi-account</v-icon>
      <v-toolbar-title class="mr-10">{{ user_name }}</v-toolbar-title>
      <v-btn color="pink darken-3" @click="logout">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <!--  -->
      <!-- <router-link to="/">DashBoard</router-link>|
      <router-link to="/grid-system">GridSystem</router-link> -->
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { bus } from "./main";
import C from "../../common/constants";
import MENU from "../menu";

export default {
  component: {
    File,
  },
  data: () => ({
    drawer: false,
    isLogin: false,
    user_level: 0,
    user_name: "",
  }),

  created() {
    bus.$on("message", this.onReceive);
    try {
      this.$http.get("/api/auth/status").then((res) => {
        console.log("##App.vue created! status:" + res.status);
        this.drawer = true;
        this.isLogin = true;
        if (res.status === C.LOGIN_ST_ROOT) {
          this.user_level = C.USER_LEVEL_0;
        } else if (res.status === C.LOGIN_ST_ADMIN_L1) {
          this.user_level = C.USER_LEVEL_1;
        } else if (res.status === C.LOGIN_ST_ADMIN_L2) {
          this.user_level = C.USER_LEVEL_2;
        } else if (res.status === C.LOGIN_ST_USER_L1) {
          this.user_level = C.USER_LEVEL_3;
        } else if (res.status === C.LOGIN_ST_USER_L2) {
          this.user_level = C.USER_LEVEL_4;
        } else {
          this.drawer = false;
          this.isLogin = false;
          this.user_level = C.USER_LEVEL_4;
        }
      });
      try {
        this.$http.get("/api/auth/user").then((res) => {
          //console.log("/api/auth/user:" + res.data);
          this.user_name = res.data;
        });
      } catch (error) {
        console.error(error);
      }
    } catch (error) {
      console.error(error);
    }
  },

  computed: {
    mini() {
      // switch (this.$vuetify.breakpoint.name) {
      //   case 'xs': return true
      //   case 'sm': return true
      //   case 'md': return true
      //   case 'lg': return true
      //   case 'xl': return true
      // }
      return false;
    },
    menu_items() {
      let menu_items = [];
      if (
        this.user_level == C.USER_LEVEL_0 ||
        this.user_level == C.USER_LEVEL_1
      ) {
        for (let item in MENU.menu_level_0) {
          if (MENU.menu_level_0[item].children === undefined) {
            menu_items.push({
              title: MENU.menu_level_0[item].title,
              icon: MENU.menu_level_0[item].icon,
              to: MENU.menu_level_0[item].to,
            });
          } else {
            menu_items.push({
              title: MENU.menu_level_0[item].title,
              icon: MENU.menu_level_0[item].icon,
              children: MENU.menu_level_0[item].children,
            });
          }
        }
      } else if (this.user_level == C.USER_LEVEL_2) {
        for (let item in MENU.menu_level_2) {
          if (MENU.menu_level_2[item].children === undefined) {
            menu_items.push({
              title: MENU.menu_level_2[item].title,
              icon: MENU.menu_level_2[item].icon,
              to: MENU.menu_level_2[item].to,
            });
          } else {
            menu_items.push({
              title: MENU.menu_level_2[item].title,
              icon: MENU.menu_level_2[item].icon,
              children: MENU.menu_level_2[item].children,
            });
          }
        }
      } else if (this.user_level == C.USER_LEVEL_3) {
        for (let item in MENU.menu_level_3) {
          if (MENU.menu_level_3[item].children === undefined) {
            menu_items.push({
              title: MENU.menu_level_3[item].title,
              icon: MENU.menu_level_3[item].icon,
              to: MENU.menu_level_3[item].to,
            });
          } else {
            menu_items.push({
              title: MENU.menu_level_3[item].title,
              icon: MENU.menu_level_3[item].icon,
              children: MENU.menu_level_3[item].children,
            });
          }
        }
      } else if (this.user_level == C.USER_LEVEL_4) {
        for (let item in MENU.menu_level_4) {
          if (MENU.menu_level_4[item].children === undefined) {
            menu_items.push({
              title: MENU.menu_level_4[item].title,
              icon: MENU.menu_level_4[item].icon,
              to: MENU.menu_level_4[item].to,
            });
          } else {
            menu_items.push({
              title: MENU.menu_level_4[item].title,
              icon: MENU.menu_level_4[item].icon,
              children: MENU.menu_level_4[item].children,
            });
          }
        }
      }
      return menu_items;
    },
  },

  methods: {
    logout() {
      this.drawer = false;
      this.isLogin = false;
      sessionStorage.removeItem("TO_URL");
      try {
        this.$http.post("/api/auth/logout").then((res) => {
          if (res.status === 200) {
            console.log("logout!");
          }
          this.$router.push("/");
        });
      } catch (error) {
        console.error(error);
      }
    },
    onReceive(text) {
      console.log("onReceive:" + text);
      this.isLogin = true;
      if (text === "root") {
        this.user_level = C.USER_LEVEL_0;
      } else if (text === "admin1") {
        this.user_level = C.USER_LEVEL_1;
      } else if (text === "admin2") {
        this.user_level = C.USER_LEVEL_2;
      } else if (text === "user1") {
        this.user_level = C.USER_LEVEL_3;
      } else if (text === "user2") {
        this.user_level = C.USER_LEVEL_4;
      } else if (text === "auth_fail") {
        this.drawer = false;
        this.isLogin = false;
      } else if (text === "logout") {
        this.logout();
      }
      try {
        this.$http.get("/api/auth/user").then((res) => {
          //console.log("/api/auth/user:" + res.data);
          this.user_name = res.data;
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>