<template>
  <v-container pa-0 fluid>
    <v-img src="@/assets/main_bg_02.jpg" height="93.4vh">
      <v-row align="start" class="lightbox white--text pa-15 fill-height">
        <v-col>
          <v-btn dark plain style="font-size: 36px" :to="`/ContactUsPage`">
            Contact Us
          </v-btn>
        </v-col>
      </v-row>
    </v-img>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    rules: {
      name: {
        required: (v) => !!v || "Category Name is required",
      },
    },
  }),

  computed: {},

  watch: {},

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      // this.loadCategory();
      // this.$http.get("/api/voc/select").then((response) => {
      //   this.items = response.data;
      //   this.loading = false;
      // });
    },
  },
};
</script>

<style>
</style>