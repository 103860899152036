import Vue from 'vue'
import axios from 'axios'
import VueRouter from "vue-router"
import LoginPage from "@/views/LoginPage"
import DashBoard from "@/views/DashBoard"
import AdminPage from "@/views/AdminPage"
import UserPage from "@/views/UserPage"
import BMP1Page from "@/views/BMP1Page"
import BMP2Page from "@/views/BMP2Page"
import BDP1Page from "@/views/BDP1Page"
import GroupPage from "@/views/GroupPage"
import QnAPage from "@/views/QnAPage"
import IntroPage from "@/views/IntroPage"
import ContactUsPage from "@/views/ContactUsPage"
import CustomerSupportPage from "@/views/CustomerSupportPage"
import ApproverPage from "@/views/ApproverPage"
import PasswordPage from "@/views/PasswordPage"
import C from "../../../common/constants"
import MENU from "../../menu"
import { bus } from "../main";

Vue.use(VueRouter)

const checkAuth = () => (to, from, next) => {
    console.log('from =>', from.path, 'to =>', to.path);
    sessionStorage.setItem("TO_URL", to.path);
    axios.get("/api/auth/status").then((response) => {
        let valid_user = false;
        if (response.status === C.LOGIN_ST_ROOT ||
            response.status === C.LOGIN_ST_ADMIN_L1)
        {
            valid_user = true;
            for (let item in MENU.menu_level_0) {
                if (MENU.menu_level_0[item].to === to.path) {
                    return next();
                }
            }
        } else if (response.status === C.LOGIN_ST_ADMIN_L2) {
            valid_user = true;
            for (let item in MENU.menu_level_2) {
                if (MENU.menu_level_2[item].to === to.path) {
                    return next();
                }
            }
        } else if (response.status === C.LOGIN_ST_USER_L1) {
            valid_user = true;
            for (let item in MENU.menu_level_3) {
                if (MENU.menu_level_3[item].to === to.path) {
                    return next();
                }
            }
        } else if (response.status === C.LOGIN_ST_USER_L2) {
            valid_user = true;
            for (let item in MENU.menu_level_4) {
                if (to.path === '/IntroPage' || MENU.menu_level_4[item].to === to.path) {
                    return next();
                }
            }
        }
        if (valid_user == true) {
            if (to.path === '/BMP1Page' || to.path === '/BMP2Page' || to.path === '/BDP1Page') {
                return next();
            } else {
                return next('/BMP1Page');
            }
        }
        console.log("checkAuth user level not found!")
        next('/')
    }).catch(() => {
        console.log("checkAuth fail!", from.path)
        bus.$emit("message", "auth_fail");
        next('/')
    });
}

const routes = [
    {
        path: '/', name: 'Login', component: LoginPage
    },
    {
        path: '/DashboardPage', name: 'DashBoard', component: DashBoard, beforeEnter: checkAuth()
    },
    {
        path: '/GroupPage', name: 'Group', component: GroupPage, beforeEnter: checkAuth()
    },
    {
        path: '/AdminPage', name: 'Admin', component: AdminPage, beforeEnter: checkAuth()
    },
    {
        path: '/UserPage', name: 'User', component: UserPage, beforeEnter: checkAuth()
    },
    {
        path: '/BMP1Page', name: 'BMP1', component: BMP1Page, beforeEnter: checkAuth()
    },
    {
        path: '/BMP2Page', name: 'BMP2', component: BMP2Page, beforeEnter: checkAuth()
    },
    {
        path: '/BDP1Page', name: 'BDP1', component: BDP1Page, beforeEnter: checkAuth()
    },
    {
        path: '/QnAPage', name: 'QnA', component: QnAPage, beforeEnter: checkAuth()
    },
    {
        path: '/IntroPage', name: 'Intro', component: IntroPage, beforeEnter: checkAuth()
    },
    {
        path: '/ContactUsPage', name: 'ContactUs', component: ContactUsPage, beforeEnter: checkAuth()
    },
    {
        path: '/CustomerSupportPage', name: 'CustomerSupport', component: CustomerSupportPage, beforeEnter: checkAuth()
    },
    {
        path: '/ApproverPage', name: 'Approver', component: ApproverPage, beforeEnter: checkAuth()
    },
    {
        path: '/PasswordPage', name: 'Password', component: PasswordPage, beforeEnter: checkAuth()
    },
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router