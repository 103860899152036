<template>
  <v-container fill-height fluid>
    <v-row justify="center">
      <v-form ref="form" lazy-validation style="width: 500px">
        <v-container>
          <v-row align="center">
            <v-col>
              <div class="mx-3">
                <v-icon color="black" size="30px">mdi-account-check</v-icon>
                기존 결재자
                <div class="mx-1">
                  <v-autocomplete
                    readonly
                    v-model="old_approver"
                    :items="approvers"
                    label="Approver"
                  ></v-autocomplete>
                </div>
              </div>
            </v-col>
            <v-col align="center">
              <v-icon color="black" size="30px">mdi-arrow-right-thick</v-icon>
            </v-col>
            <v-col>
              <div class="mx-3">
                <v-icon color="black" size="30px">mdi-account-check</v-icon>
                변경 결재자
                <div class="mx-1">
                  <v-autocomplete
                    v-model="new_approver"
                    :items="approvers"
                    label="Approver"
                    :rules="[rules.approver.required]"
                  ></v-autocomplete>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <v-container></v-container>
        <v-card-actions>
          <v-btn color="#2c4f91" dark large block @click="changeApprove"
            >Change</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-row>
  </v-container>
</template>

<script>
// import { bus } from '../main';

export default {
  data: () => ({
    old_approver: "",
    new_approver: "",
    approvers: [],
    items: [],
    rules: {
      approver: {
        required: (v) => !!v || "New approver is required",
      },
    },
  }),

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      try {
        this.$http.get("/api/admin/approver/select").then((response) => {
          let item = response.data;
          if (item.length > 0) {
            this.old_approver = item[0].name;
          }
        });
        this.$http.get("/api/approver/select").then((response) => {
          this.items = response.data;
          for (let i in this.items) {
            this.approvers.push(this.items[i].name);
          }
        });
      } catch (error) {
        console.error(error);
      }
    },

    changeApprove() {
      if (!this.$refs.form.validate()) return;
      let new_approver_no = -1;
      for (let i in this.items) {
        if (this.new_approver === this.items[i].name) {
          new_approver_no = this.items[i].user_no;
        }
      }
      try {
        let params = {
          new_approver_no: new_approver_no,
        };
        this.$http
          .post("/api/admin/approver/update", { params: params })
          .then((response) => {
            let item = response.data;
            this.old_approver = item[0].name;
            this.new_approver = " ";
          });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style>
</style>