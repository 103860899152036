<template>
  <v-container fluid>
    <v-data-table
      :single-select="singleSelect"
      :headers="headers"
      :items="items"
      :search="search"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      v-model="selectedRows"
      item-key="binary_no"
      hide-default-footer
      sort-by="binary_no"
      sort-desc
      class="elevation-1"
      @page-count="pageCount = $event"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ product_type }} ▶ {{ product_name }} </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="search"
                class="pa-5"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <v-dialog v-model="dialog" persistent max-width="800px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="green"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                v-show="isAdmin2"
                @click="openNewDialog"
              >
                NEW
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <v-container fluid>
                  <v-form ref="form" lazy-validation>
                    <v-container fluid>
                      <v-row>
                        <!-- 결재현황 -->
                        <v-col cols="6">
                          <table>
                            <tr style="height: 80px">
                              <td
                                rowspan="2"
                                style="border: 1px solid black"
                                class="pa-2"
                              >
                                결재
                              </td>
                              <td
                                style="width: 80px; border: 1px solid black"
                                class="text-center"
                              >
                                <!-- 결재1 -->
                                <v-container>
                                  <v-img
                                    v-if="
                                      editedItem.approve_status == 'approved'
                                    "
                                    contain
                                    max-width="60"
                                    src="@/assets/approved.png"
                                  ></v-img>
                                  <v-img
                                    v-else-if="
                                      editedItem.approve_status == 'rejected'
                                    "
                                    contain
                                    max-width="60"
                                    src="@/assets/rejected.png"
                                  ></v-img>
                                </v-container>
                              </td>
                              <td
                                style="width: 80px; border: 1px solid black"
                                class="text-center"
                              >
                                <!-- 결재2 -->
                              </td>
                              <td
                                style="width: 80px; border: 1px solid black"
                                class="text-center"
                              >
                                <!-- 결재3 -->
                              </td>
                            </tr>
                            <tr>
                              <td
                                style="border: 1px solid black"
                                class="text-center"
                              >
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                      {{ editedItem.approver }}
                                    </span>
                                  </template>
                                  <span>{{ editedItem.approve_comment }}</span>
                                </v-tooltip>
                              </td>
                              <td
                                style="border: 1px solid black"
                                class="text-center"
                              ></td>
                              <td
                                style="border: 1px solid black"
                                class="text-center"
                              ></td>
                            </tr>
                            <tr>
                              <td style="border: 1px solid black" class="pa-2">
                                참조
                              </td>
                              <td
                                colspan="3"
                                style="border: 1px solid black"
                                class="text-center"
                              >
                                <v-combobox
                                  v-model="editedItem.sel_cc_group"
                                  :items="cc_groups"
                                  :rules="[rules.cc_groups.required]"
                                  label="참조그룹"
                                  multiple
                                  chips
                                  @change="updateSelGroups($event)"
                                ></v-combobox>
                              </td>
                            </tr>
                          </table>
                        </v-col>

                        <!-- 등록옵션 -->
                        <v-col cols="6">
                          <v-checkbox
                            v-model="editedItem.notification"
                            :label="`알림여부`"
                          >
                          </v-checkbox>
                          <v-combobox
                            v-model="editedItem.sel_group"
                            :items="groups"
                            label="게시물 접근권한 선택"
                            :rules="[rules.sel_group.required]"
                            multiple
                            chips
                            @change="updateSelGroups($event)"
                          ></v-combobox>
                          <v-autocomplete
                            v-model="editedItem.sel_cat"
                            :items="categories"
                            label="Category"
                            :rules="[rules.category.required]"
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-row>
                      <v-col cols="4">
                        <template>
                          <v-switch
                            v-model="editedItem.urgent"
                            :label="`긴급: ${editedItem.urgent.toString()}`"
                          >
                          </v-switch>
                        </template>
                      </v-col>
                      <v-col v-if="editedItem.urgent" cols="4">
                        <v-subheader class="pl-0">
                          알림 주기(1~9999분)
                        </v-subheader>
                        <v-text-field
                          v-model="editedItem.alert_period"
                          :rules="[rules.alarm_period.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col v-if="editedItem.urgent" cols="4">
                        <v-subheader class="pl-0">
                          알림 회수(1~99)
                        </v-subheader>
                        <v-text-field
                          v-model="editedItem.alert_count"
                          :rules="[rules.alarm_count.required]"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.bin_title"
                          label="제목*"
                          :rules="[rules.title.required]"
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          v-model="editedItem.bin_content"
                          color="cyan"
                          label="내용"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="editedItem.ver_major"
                          label="VER_MAJOR*"
                          :rules="[rules.version.required]"
                          hide-details="auto"
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="editedItem.ver_minor"
                          label="VER_MINOR*"
                          :rules="[rules.version.required]"
                          hide-details="auto"
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-if="editedItem.sel_cat === 'GUI & Firmware'"
                          v-model="editedItem.ver_patch"
                          label="VER_PATCH*"
                          :rules="[rules.version.required]"
                          hide-details="auto"
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-file-input
                          v-model="editedItem.filename"
                          label="Select FILE*"
                          show-size
                          :rules="[rules.file.required]"
                        ></v-file-input>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close"> 취소 </v-btn>
                <v-btn
                  v-if="
                    (editedItem.approve_status != 'approved' &&
                      editedItem.owner == user_no) ||
                    (editedIndex === -1 && approvers.length > 0)
                  "
                  color="blue darken-1"
                  text
                  @click="save"
                >
                  상신
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogApprove" persistent max-width="800px">
            <v-card>
              <v-card-text>
                <v-container fluid>
                  <v-banner>
                    <v-row>
                      <v-col cols="6">
                        {{ product_name }} {{ editedItem.cat_name }} Updated
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col>
                        <h5>
                          {{ editedItem.date }}
                        </h5>
                      </v-col>
                    </v-row>
                  </v-banner>
                  <v-container>
                    <v-row align="center" no-gutters>
                      <v-col>
                        <v-toolbar-title>{{ product_type }} ▶ {{ product_name }} </v-toolbar-title>
                      </v-col>
                      <v-col>
                        <v-spacer></v-spacer>
                      </v-col>
                      <v-col cols="2">
                        다운로드수: {{ editedItem.view_count }}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.bin_title"
                          label="제목"
                          :rules="[rules.title.required]"
                          hide-details="auto"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          v-model="editedItem.bin_content"
                          color="cyan"
                          label="내용"
                          readonly
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-card elevation="2" outlined>
                      <v-container>
                        <v-row>
                          <v-col class="pa-0">
                            <v-card color="blue darken-1" dark>
                              <v-container>
                                <v-row class="text-center">
                                  <v-col> 결재 </v-col>
                                </v-row>
                              </v-container>
                            </v-card>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-text-field
                              v-model="editedItem.approve_comment"
                              label="커멘트"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row align="center">
                          <v-col>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="closeApprove"
                            >
                              취소
                            </v-btn>
                          </v-col>
                          <v-col
                            v-if="
                              editedItem.approve_status !== 'approved' &&
                              editedItem.approve_status !== 'rejected' &&
                              editedItem.approver === name
                            "
                          >
                            <v-btn color="error" text @click="saveRejected">
                              반려
                            </v-btn>
                            <v-btn color="success" text @click="saveApproved">
                              승인
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-container>
                </v-container>
              </v-card-text>
              <v-card-actions> </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" persistent max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogView" persistent max-width="800px">
            <v-card>
              <v-card-text class="pt-5">
                <v-container fluid>
                  <v-banner>
                    <v-row>
                      <v-col cols="8">
                        {{ product_name }} {{ editedItem.cat_name }} Updated
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col>
                        <h5>
                          {{ editedItem.date }}
                        </h5>
                      </v-col>
                    </v-row>
                  </v-banner>
                </v-container>
                <v-container fluid>
                  <v-row align="center" no-gutters>
                    <v-col>
                      <v-toolbar-title>{{ product_type }} ▶ {{ product_name }} </v-toolbar-title>
                    </v-col>
                    <v-col>
                      <v-spacer></v-spacer>
                    </v-col>
                    <v-col class="text-right">
                      <v-icon color="primary"> mdi-paperclip </v-icon>
                    </v-col>
                    <v-col>
                      <a @click="downloadItem(editedItem)">
                        {{ editedItem.filename }}
                      </a>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="editedItem.bin_title"
                        label="Title"
                        :rules="[rules.title.required]"
                        hide-details="auto"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="editedItem.bin_content"
                        color="cyan"
                        label="Contents"
                        readonly
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeView">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogApproveLog" persistent max-width="800px">
            <v-card>
              <v-card-title>
                <span class="text-h5">결재 내역</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-data-table
                    :headers="log_headers"
                    :items="log_items"
                    sort-by="user_no"
                    class="elevation-1"
                  ></v-data-table>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="closeApproveLogDialog"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>

        <v-container fluid>
          <v-chip-group active-class="primary--text">
            <v-chip
              v-for="cat in categories"
              :key="cat"
              @click="onChipClick(cat)"
            >
              {{ cat }}
            </v-chip>
          </v-chip-group>
        </v-container>
      </template>
      <template v-slot:[`item.latest`]="{ item }">
        <v-icon color="purple darken-2" small v-show="item.latest"
          >mdi-star</v-icon
        >
      </template>
      <template v-slot:[`item.group_name`]="{ item }">
        <v-chip v-for="grp in item.group_name" :key="grp" small>
          {{ grp }}
        </v-chip>
      </template>
      <template v-slot:[`item.bin_title`]="{ item }">
        <a @click="clickItem(item)">
          {{ item.bin_title }}
        </a>
      </template>
      <template v-slot:[`item.filename`]="{ item }">
        <a @click="downloadItem(item)">
          {{ item.filename }}
        </a>
      </template>
      <template v-slot:[`item.version`]="{ item }">
        {{ getVersion(item) }}
      </template>
      <template v-slot:[`item.approve_status`]="{ item }">
        <v-chip
          v-model="item.approve_status"
          :color="
            item.approve_status === 'new'
              ? 'yellow'
              : item.approve_status === 'processed'
              ? 'green'
              : item.approve_status === 'rejected'
              ? 'red'
              : item.approve_status === 'approved'
              ? 'blue'
              : 'orange'
          "
          @click="onStatusChipClick(item)"
          >{{ item.approve_status }}</v-chip
        >
      </template>
      <template v-slot:[`item.hidden`]="{ item }">
        <v-checkbox
          class="d-inline-flex ml-3"
          v-model="item.hidden"
          @change="onHiddenChanged(item)"
        ></v-checkbox>
      </template>
    </v-data-table>
    <div class="text-center pa-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        :total-visible="totalVisible"
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
      ></v-pagination>
    </div>
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import C from "../../../common/constants";
import { bus } from "../main";

export default {
  data: () => ({
    product_type: "",
    product_name: "",
    user_level: -1,
    user_no: -1,
    name: "",
    dialog: false,
    dialogDelete: false,
    dialogApprove: false,
    dialogView: false,
    dialogApproveLog: false,
    loading: false,
    log_headers: [
      { text: "Date", value: "approve_date", align: "center", sortable: false },
      {
        text: "Status",
        value: "approve_status",
        align: "center",
        sortable: false,
      },
      {
        text: "Comment",
        value: "approve_comment",
        align: "center",
        sortable: false,
      },
    ],
    log_items: [],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    totalVisible: 10,
    search: "",
    selectedRows: [],
    singleSelect: false,
    items: [],
    approvers: [],
    categories: [],
    group_info: [],
    groups: [],
    cc_groups: [],
    isAdmin2: false,
    editedIndex: -1,
    editedItem: {
      binary_no: 0,
      notification: 1,
      latest: false,
      group_name: [],
      sel_group: [],
      sel_cc_group: [],
      sel_cat: "",
      filename: [],
      sha256: "",
      ver_major: "",
      ver_minor: "",
      ver_patch: "",
      date: "",
      cat_no: 0,
      cat_name: "",
      type: "",
      title: "",
      content: "",
      urgent: false,
      alert_period: 30,
      alert_count: 10,
      approve_name: "",
      approve_status: "",
      approve_comment: "",
      hidden: null,
    },
    defaultItem: {
      binary_no: 0,
      notification: 1,
      latest: false,
      group_name: [],
      sel_group: [],
      sel_cc_group: [],
      sel_cat: "",
      filename: [],
      sha256: "",
      ver_major: "",
      ver_minor: "",
      ver_patch: "",
      date: "",
      cat_no: 0,
      cat_name: "",
      type: "",
      title: "",
      content: "",
      urgent: false,
      alert_period: 30,
      alert_count: 10,
      approve_name: "",
      approve_status: "",
      approve_comment: "",
      hidden: null,
    },
    rules: {
      cc_groups: {
        required: (v) => (v && v.length > 0) || "CC Group is required",
      },
      sel_group: {
        required: (v) => (v && v.length > 0) || "Group is required",
      },
      category: {
        required: (v) => !!v || "Category is required",
      },
      alarm_period: {
        required: (v) => (Number(v) > 0 && Number(v) < 10000) || "1 ~ 9999",
      },
      alarm_count: {
        required: (v) => (Number(v) > 0 && Number(v) < 100) || "1 ~ 99",
      },
      title: {
        required: (v) => !!v || "Title is required",
      },
      content: {
        required: (v) => v || "Content is required",
      },
      file: {
        required: (v) => (v && v.size > 0) || "File Selection is required",
      },
      version: {
        required: (v) => !!v || "Version is required",
      },
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Upload" : "Upload";
    },
    headers() {
      let headers = [
        { text: "No.", value: "binary_no", align: "center", sortable: false },
        { text: "Updated", value: "latest", align: "center", sortable: false },
      ];
      if (this.user_level >= C.LOGIN_ST_ADMIN_L1) {
        headers.push({
          text: "Group",
          value: "group_name",
          align: "center",
          sortable: false,
        });
      }
      headers.push({
        text: "Category",
        value: "cat_name",
        align: "center",
        sortable: false,
      });
      headers.push({
        text: "Title",
        value: "bin_title",
        align: "center",
        sortable: false,
      });
      headers.push({
        text: "Attached File",
        value: "filename",
        align: "center",
        sortable: false,
      });
      headers.push({
        text: "Version",
        value: "version",
        align: "center",
        sortable: false,
      });
      headers.push({
        text: "Date",
        value: "date",
        align: "center",
        sortable: false,
      });
      if (this.user_level >= C.LOGIN_ST_ADMIN_L1) {
        headers.push({
          text: "Approver",
          value: "approver",
          align: "center",
          sortable: false,
        });
        headers.push({
          text: "Status",
          value: "approve_status",
          align: "center",
          sortable: false,
        });
        headers.push({
          text: "Hidden",
          value: "hidden",
          align: "center",
          sortable: false,
        });
        if (this.user_level >= C.LOGIN_ST_ROOT) {
          headers.push({
            text: "Owner",
            value: "owner",
            align: "center",
            sortable: false,
          });
          headers.push({
            text: "Path",
            value: "filepath",
            align: "center",
            sortable: false,
          });
        }
        headers.push({
          text: "Download Count",
          value: "view_count",
          align: "center",
          sortable: false,
        });
      }
      return headers;
    },
  },

  watch: {
    dialog(val) {
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      //현재 파일명+확장자 얻기        
      var thisfilefullname = document.URL.substring(document.URL.lastIndexOf('/') + 1, document.URL.length);
      console.log('thisfilefullname', thisfilefullname);
      if (thisfilefullname === 'BMP1Page') {
        this.product_type = "BMS";
        this.product_name = "ATS7001";
      } else if (thisfilefullname === 'BMP2Page') {
        this.product_type = "BMS";
        this.product_name = "ATS7002";
      } else if (thisfilefullname === 'BDP1Page') {
        this.product_type = "BDS";
        this.product_name = "BDIC";
      } else {
        this.product_type = "ERR";
        this.product_name = "ERR";
      }

      this.loading = true;
      this.$http.get("/api/auth/status").then((response) => {
        this.user_level = response.status;
        if (response.status == C.LOGIN_ST_ROOT) {
          this.user_no = -1;
          this.name = "root";
        } else {
          this.user_no = response.data[0].user_no;
          this.name = response.data[0].name;
        }
        this.isAdmin2 = false;
        if (
          response.status == C.LOGIN_ST_ADMIN_L2 ||
          response.status == C.LOGIN_ST_ROOT
        ) {
          this.isAdmin2 = true;
        }
        this.loadCategory();
        this.loadGroup();
        this.fetchItems();
        this.fetchApprovers();
      });
    },

    updateDateFormat() {
      for (var key in this.items) {
        this.items[key].date = this.$moment(this.items[key].date).format(
          "YYYY-MM-DD HH:mm"
        );
      }
    },

    async fetchItems() {
      return await this.$http
        .get("/api/binary/select?product=" + this.product_name)
        .then((response) => {
          this.items = response.data;
          if (this.items.length > 0) {
            this.showLatestItems(this.items);
            this.transGroupNumToGroupNames(this.items);
            this.transCcGroupNumToCcGroupNames(this.items);
            this.highlightApproveNeededItems(this.items);
            this.loading = false;
          }
          console.log("fetchItems done.");
          return true;
        })
        .catch((exception) => {
          if (exception.response.status == C.ERROR_LOGIN_FAILED) {
            bus.$emit("message", "logout");
            return false;
          }
          alert("fetchItems 실패\n" + exception);
          return false;
        });
    },

    async fetchApprovers() {
      await this.$http.get("/api/admin/approver/select").then((response) => {
        let item = response.data;
        if (item.length > 0) {
          this.approvers.push(item[0].name);
        }
        console.log("+this.approvers[0]:", this.approvers[0]);
      });
    },

    openNewDialog() {
      // if (this.$refs.form) {
      //   this.$refs.form.reset();
      // }
      // this.loadCategory();
      this.editedItem.approver = this.approvers[0];
    },

    loadCategory() {
      this.$http.get("/api/category/select").then((response) => {
        let items = response.data;
        for (var key in items) {
          // console.log(key, response.data[key].cat_name);
          this.categories[key] = items[key].cat_name;
        }
        this.loading = false;
      });
    },

    loadGroup() {
      this.$http.get("/api/group/select").then((response) => {
        this.group_info = response.data;
        for (var key in response.data) {
          this.groups[key] = response.data[key].group_name;
          if (response.data[key].group_level == 3) {
            this.cc_groups[key] = response.data[key].group_name;
          }
        }
        this.loading = false;
      });
    },

    checkLatestItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },

    showLatestItems(items) {
      for (let idx in items) {
        //if (items[idx].binary_no === items[idx].latest_bin) {
        let given = this.$moment(items[idx].date);
        let current = this.$moment().startOf("day");
        let days = Math.abs(
          this.$moment.duration(given.diff(current)).asDays()
        );
        // console.log("days:", days);
        if (days < 14) {
          items[idx].latest = true;
        } else {
          items[idx].latest = false;
        }
        //} else {
        //items[idx].latest = false;
        //}
      }
      this.updateDateFormat();
    },

    transGroupNumToGroupNames(items) {
      for (let idx in items) {
        let item_groups = [];
        let item_group_names = items[idx].group_name.split(",");
        for (let idx in item_group_names) {
          for (let ginfo in this.group_info) {
            if (this.group_info[ginfo].group_no == item_group_names[idx]) {
              item_groups.push(this.group_info[ginfo].group_name);
            }
          }
        }
        items[idx].group_name = item_groups;
      }
    },

    transCcGroupNumToCcGroupNames(items) {
      for (let idx in items) {
        let item_cc_groups = [];
        let item_cc_group_names = items[idx].cc_group_name.split(",");
        for (let idx in item_cc_group_names) {
          for (let ginfo in this.group_info) {
            if (this.group_info[ginfo].group_no == item_cc_group_names[idx]) {
              item_cc_groups.push(this.group_info[ginfo].group_name);
            }
          }
        }
        items[idx].cc_group_name = item_cc_groups;
      }
    },

    highlightApproveNeededItems(items) {
      this.selectedRows = [];
      for (var key in items) {
        if (
          items[key].approve_status != "approved" &&
          items[key].approve_status != "rejected"
        ) {
          this.selectedRows.push({
            binary_no: items[key].binary_no,
          });
        }
      }
    },

    downloadItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.loading = true;
      this.$http
        .get("/api/binary/download", {
          responseType: "blob",
          params: this.editedItem,
        })
        .then((response) => {
          // const name = response.headers["content-disposition"]
          // .split("filename=")[1]
          // .replace(/"/g, "");
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", this.editedItem.filename);
          document.body.appendChild(link);
          link.click();
          link.remove();
          this.updateViewCount(this.editedItem);
          this.loading = false;
        })
        .catch((exception) => {
          this.loading = false;
          if (exception.response.status == C.ERROR_LOGIN_FAILED) {
            bus.$emit("message", "logout");
            return;
          }
          alert("파일 다운로드 실패\n" + exception);
        });
    },

    async editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      await this.fetchItems();
      this.editedItem = Object.assign({}, this.items[this.editedIndex]);
      this.editedItem.sel_group = item.group_name;
      this.editedItem.sel_cc_group = item.cc_group_name;
      this.editedItem.sel_cat = item.cat_name;
      this.editedItem.filename = [];
      this.editedItem.approver = item.approver;
      this.dialog = true;
      console.log("item.urgent:", this.editedItem.urgent);
      if (this.editedItem.urgent === "true") {
        this.editedItem.urgent = true;
      } else {
        this.editedItem.urgent = false;
      }
    },

    async viewItem(item) {
      this.editedIndex = this.items.indexOf(item);
      await this.fetchItems().then(res=>{
        console.log('viewItem:', res)
        if (res) {
          this.editedItem = Object.assign({}, this.items[this.editedIndex]);
          this.editedItem.sel_group = item.group_name;
          this.editedItem.sel_cc_group = item.cc_group_name;
          this.dialogView = true;
          this.$http
            .post("/api/binary/checked", { params: this.editedItem })
            .then(() => {});
        }
      });
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$http
        .get("/api/binary/delete", { params: this.editedItem })
        .then((response) => {
          this.items = response.data;
          this.showLatestItems(this.items);
          this.loading = false;
        });
      this.items.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        //this.$router.go(); // page refresh
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeView() {
      this.dialogView = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;
      this.dialog = false;
      let url;
      let formData = new FormData();
      if (this.editedIndex > -1) {
        Object.assign(this.items[this.editedIndex], this.editedItem);
        url = "/api/binary/update";
        formData.append("binary_no", this.editedItem.binary_no);
      } else {
        url = "/api/binary/upload";
      }
      formData.append("binary", this.editedItem.filename);
      formData.append("item", this.editedItem);
      formData.append("ver_major", this.editedItem.ver_major);
      formData.append("ver_minor", this.editedItem.ver_minor);
      formData.append("ver_patch", this.editedItem.ver_patch);
      formData.append("cat_name", this.editedItem.sel_cat);
      formData.append("cc_group_name", this.editedItem.sel_cc_group);
      formData.append("group_name", this.editedItem.sel_group);
      if (this.editedItem.notification) {
        formData.append("notification", 1);
      } else {
        formData.append("notification", 0);
      }
      formData.append("title", this.editedItem.bin_title);
      formData.append("content", this.editedItem.bin_content);
      formData.append("product_type", this.product_type);
      formData.append("product_name", this.product_name);
      formData.append("urgent", this.editedItem.urgent);
      formData.append("alert_period", this.editedItem.alert_period);
      formData.append("alert_count", this.editedItem.alert_count);
      this.$http
        .post(url, formData)
        .then((response) => {
          this.items = response.data;
          this.showLatestItems(this.items);
          this.transGroupNumToGroupNames(this.items);
          this.transCcGroupNumToCcGroupNames(this.items);
          //console.log("binary_no=%d", binary_no);
          this.loading = false;
          this.$router.go(); // page refresh
        })
        .catch((error) => {
          console.log({ error });
          bus.$emit("message", "logout");
          this.loading = false;
        });
    },

    clickItem(item) {
      if (this.user_level === C.LOGIN_ST_ADMIN_L1) {
        this.approveItem(item);
      } else if (this.user_level === C.LOGIN_ST_ADMIN_L2) {
        this.editItem(item);
      } else {
        this.viewItem(item);
      }
    },

    updateViewCount(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.$http
        .post("/api/binary/view_count/update", { params: this.editedItem })
        .then((response) => {
          this.items = response.data;
          //console.log('view_count:', this.items[0].view_count);
          if (this.items.length > 0) {
            this.showLatestItems(this.items);
            this.transGroupNumToGroupNames(this.items);
            this.transCcGroupNumToCcGroupNames(this.items);
            this.highlightApproveNeededItems(this.items);
            this.loading = false;
          }
          //
        });
    },

    async approveItem(item) {
      this.editedIndex = this.items.indexOf(item);
      await this.fetchItems();
      this.editedItem = Object.assign({}, this.items[this.editedIndex]);
      this.editedItem.sel_group = item.group_name;
      this.editedItem.sel_cc_group = item.cc_group_name;
      this.editedItem.approver = item.approver;
      if (item.approve_status === "new") {
        this.$http
          .post("/api/binary/approve/processed", { params: this.editedItem })
          .then(() => {
            // this.loading = false;
            // this.items = response.data;
          });
      }

      this.dialogApprove = true;
    },

    closeApprove() {
      this.dialogApprove = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeApproveLogDialog() {
      this.dialogApproveLog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    saveApproved() {
      this.$http
        .post("/api/binary/approve/approved", { params: this.editedItem })
        .then((response) => {
          this.highlightApproveNeededItems(response.data);
          this.items = response.data;
          this.dialogApprove = false;
          this.showLatestItems(this.items);
          this.transGroupNumToGroupNames(this.items);
          this.transCcGroupNumToCcGroupNames(this.items);
          this.highlightApproveNeededItems(this.items);
        });
    },

    saveRejected() {
      this.$http
        .post("/api/binary/approve/rejected", { params: this.editedItem })
        .then((response) => {
          this.highlightApproveNeededItems(response.data);
          this.items = response.data;
          this.dialogApprove = false;
          this.showLatestItems(this.items);
          this.transGroupNumToGroupNames(this.items);
          this.transCcGroupNumToCcGroupNames(this.items);
          this.highlightApproveNeededItems(this.items);
        });
    },

    onChipClick(cat) {
      console.log("[onChipClick]", cat);
      if (this.search === cat) {
        this.search = "";
      } else {
        this.search = cat;
      }
      this.sel_cat = cat;
    },

    async onStatusChipClick(item) {
      console.log("[onStatusChipClick]", item);
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, this.items[this.editedIndex]);
      this.$http
        .get("/api/binary/approve/status", { params: this.editedItem })
        .then((response) => {
          // this.loading = false;
          this.log_items = response.data;
        });

      this.dialogApproveLog = true;
    },

    getVersion(item) {
      let version = item.ver_major + "." + item.ver_minor;
      if (item.ver_patch) {
        version += "." + item.ver_patch;
      }
      return version;
    },

    updateSelGroups(e) {
      this.$nextTick(() => {
        this.$nextTick(() => {
          console.log("complete ", e);
          this.editedItem.approve_status = "new";
        });
      });
    },

    onHiddenChanged(item) {
      console.log("onHiddenChanged!", item.hidden);
      this.$http.post("/api/binary/hidden", { params: item }).then(() => {
        // this.loading = false;
      });
    },
  },
};
</script>

<style>
</style>