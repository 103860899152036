<template>
  <v-container fill-height fluid>
    <v-row justify="center">
      <v-form ref="form" lazy-validation style="width: 400px">
        <div class="mx-3">
          <v-img src="@/assets/ats_logo.png" contain class="mb-5"></v-img>
        </div>
        <div class="mx-3">
          <v-icon color="black" size="30px">mdi-login</v-icon> id
          <div class="mx-1">
            <v-text-field
              placeholder="email"
              v-model="editedItem.userId"
              :rules="[rules.email.required]"
            ></v-text-field>
          </div>
        </div>
        <div class="mx-3">
          <v-icon color="black" size="30px">mdi-lock</v-icon>
          password
          <div class="mx-1">
            <v-text-field
              placeholder="password"
              type="password"
              v-model="editedItem.userPassword"
              :rules="[rules.password.required]"
              @keyup.enter="loginSubmit"
            ></v-text-field>
          </div>
        </div>
        <div class="mx-3">
          <v-btn
            color="pink"
            dark
            block
            text
            @click="dialogResetPassword = true"
            >Reset Password</v-btn
          >
        </div>
        <v-card-actions>
          <v-btn color="#2c4f91" dark large block @click="loginSubmit"
            >Login</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-row>
    <v-snackbar color="red" v-model="alert">
      <div class="text-center">LOGIN ERROR!</div>
    </v-snackbar>
    <v-snackbar color="red" v-model="alert_reset_password">
      <div class="text-center">PASSWORD RESET ERROR!</div>
    </v-snackbar>
    <v-snackbar color="green" v-model="confirm_reset_password">
      <div class="text-center">Temporary password mail transfer completed!</div>
    </v-snackbar>
    <v-dialog v-model="dialogResetPassword" persistent max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >Are you sure you want to reset your password?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeResetPassword"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="resetPasswordConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { bus } from "../main";
import C from "../../../common/constants";

export default {
  data: () => ({
    alert: false,
    alert_reset_password: false,
    confirm_reset_password: false,
    dialog: false,
    dialogResetPassword: false,
    items: [],
    editedIndex: -1,
    editedItem: {
      userId: "",
      userPassword: "",
    },
    defaultItem: {
      userId: "",
      userPassword: "",
    },
    rules: {
      email: {
        required: (v) => !!v || "E-Mail is required",
      },
      password: {
        required: (v) => !!v || "Password is required",
      },
    },
  }),

  created() {
    this.initialize();
    //getPost('requestPost', this.$route.params.id);
  },

  watch: {
    alert(new_val) {
      if (new_val) {
        setTimeout(() => {
          this.alert = false;
        }, 1000);
      }
    },
    alert_reset_password(new_val) {
      if (new_val) {
        setTimeout(() => {
          this.alert_reset_password = false;
        }, 3000);
      }
    },
    confirm_reset_password(new_val) {
      if (new_val) {
        setTimeout(() => {
          this.confirm_reset_password = false;
        }, 3000);
      }
    },
  },

  computed: {
    old_path() {
      console.log("old_path:", this.$route.params.old_path);
      return this.$route.params.old_path;
    },
  },

  methods: {
    initialize() {},

    loginSubmit() {
      if (!this.$refs.form.validate()) return;
      console.log("loginSubmit");
      try {
        this.$http
          .post("/api/auth/login", { params: this.editedItem })
          .then((res) => {
            let to_url = sessionStorage.getItem("TO_URL");
            console.log("TO_URL:", to_url);
            if (res.status === C.LOGIN_ST_USER_L1) {
              // 로그인 성공시 처리해줘야할 부분
              bus.$emit("message", "user1");
              if (to_url == null) to_url = "/BMP1Page";
              this.$router.push(to_url);
            } else if (res.status === C.LOGIN_ST_USER_L2) {
              bus.$emit("message", "user2");
              if (to_url == null) to_url = "/IntroPage";
              this.$router.push(to_url);
            } else if (res.status === C.LOGIN_ST_ROOT) {
              bus.$emit("message", "root");
              if (to_url == null) to_url = "/DashboardPage";
              this.$router.push(to_url);
            } else if (res.status === C.LOGIN_ST_ADMIN_L1) {
              bus.$emit("message", "admin1");
              if (to_url == null) to_url = "/DashboardPage";
              this.$router.push(to_url);
            } else if (res.status === C.LOGIN_ST_ADMIN_L2) {
              bus.$emit("message", "admin2");
              if (to_url == null) to_url = "/DashboardPage";
              this.$router.push(to_url);
            } else {
              this.alert = true;
              console.log("login error1");
            }
          })
          .catch((error) => {
            if (error.response.status === C.ERROR_LOGIN_MAX_FAILED) {
              console.log("Too many failed attempts!");
            }
            this.alert = true;
            console.log(error.response);
          });
      } catch (error) {
        this.alert = true;
        console.log("login error2");
        // console.error(error);
      }
    },

    closeResetPassword() {
      this.dialogResetPassword = false;
    },

    resetPasswordConfirm() {
      try {
        this.$http
          .post("/api/auth/verifyResetPassword", { params: this.editedItem })
          .then((res) => {
            if (res.status === C.HTTP_ST_200) {
              // 로그인 성공시 처리해줘야할 부분
              this.confirm_reset_password = true;
            } else {
              this.alert_reset_password = true;
              console.log("login error1");
            }
          })
          .catch((error) => {
            this.alert_reset_password = true;
            console.log(error.response);
          });
      } catch (error) {
        this.alert_reset_password = true;
        console.log("login error2");
      }
      this.dialogResetPassword = false;
    },
  },
};
</script>

<style>
</style>